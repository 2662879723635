import { computed, reactive, ref } from 'vue'
import { toaster } from '@/utils/toaster'
import { useWakeLock } from '@vueuse/core'
import { logError } from '@/utils/error-logger'
import consultationDataService from '@/services/consultationDataService'
import userDataService from '@/services/userDataService'

import { useConsultationsStore } from '@/stores/consultations'
import { useUserDataStore } from '@/stores/user'
import useDeviceAndBrowser from './useDeviceAndBrowser'
import { useI18n } from 'vue-i18n'
import { useRecordStore } from '@/stores/record'
import { useTimer } from './useTimer'
import { useForegroundService } from './useForegroundSerice'

export default function useAudioRecording(callback: Function, minDurationSec: number = 10) {
  const { t } = useI18n()
  const recordStore = useRecordStore()
  const { startTimer, pauseTimer, resumeTimer, stopTimer, resetTimer, durationMms, duration } =
    useTimer(onUpdateTimerCallback)
  const { startForegroundService, stopForegroundService } = useForegroundService()

  const selectedFile = ref(false)
  const state = reactive({
    file: '',
    description: '',
    fileName: '',
    audioFiles: []
  })
  const isMicAccessWasLost = ref(false)
  const isNotificationPermissionProvided = ref(true)
  const initRecording = ref(false)
  const isPaused = ref(false)
  const isAvailableToStore = computed(() => Math.floor(durationMms.value / 1000) >= minDurationSec)

  const consultationId = ref<string>('')
  const uploading = ref(false)
  const chunkBuffer = ref<Record<number, FormData>>({})
  const chunkCounter = ref(0)
  const stream = ref<MediaStream | null>(null)
  const recordingDevice = ref<string>('')

  const consultationStartTime = ref<Date | null>(null)
  const { userAgent } = useDeviceAndBrowser()
  const userStore = useUserDataStore()
  const consultationsStore = useConsultationsStore()

  const _mimeTypes = ['audio/webm;codecs=opus', 'audio/webm', 'audio/mp4', 'audio/aac']
  const mediaRecorder = ref<MediaRecorder | null>(null)

  const supportedMimeType = computed(() =>
    _mimeTypes.find((type) => MediaRecorder.isTypeSupported(type))
  )

  const { request, release, isSupported } = useWakeLock()

  async function startRecording() {
    let microphones
    let activeMicrophone
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      recordStore.isRecording = true // Update the recording state
      initRecording.value = true

      try {
        //stream.value = await navigator.mediaDevices.getUserMedia({ audio: true })
        stream.value = await navigator.mediaDevices.getUserMedia({
          audio: {
            sampleRate: 16000, // Set the sample rate
            channelCount: 1 // Set the number of audio channels
            //bitrate: 128000    // Set the bitrate (in bits per second)
          }
        })

        isNotificationPermissionProvided.value = await startForegroundService()

        if (!isNotificationPermissionProvided.value) {
          throw new Error(t(`capacitorAndroid.toaster.permissionIsNotGrunted`))
        }

        // List all media devices and extract microphones list
        const devices = await navigator.mediaDevices.enumerateDevices()
        microphones = JSON.stringify(devices.filter((device) => device.kind === 'audioinput'))

        // Determine the active microphone device
        activeMicrophone = devices.find(
          (device) => device.label === stream.value?.getAudioTracks()[0].label
        )
        console.log('Active microphone device sting:', JSON.stringify(activeMicrophone))
        recordingDevice.value = activeMicrophone?.label || ''
      } catch (error: any) {
        console.error(error)
        logError(
          'Error: probably user deny to record audio',
          'useAudioRecording.ts:startRecording()',
          error
        )

        toaster.error(
          t('consultationIndexView.recordingProcess.toaster.micAccessDoesNotProvided'),
          { duration: Infinity, closeButton: true, dismissible: true }
        )
        isNotificationPermissionProvided.value = false
        recordStore.isRecording = false
        // toaster.error("Allow microphone access! Please reload page.", { closeButton: true, duration: Infinity });
        return
      }

      try {
        // Create consultation
        let microphone = JSON.stringify(activeMicrophone)
        const {
          data: { consultation }
        } = await consultationDataService.createConsultation({ userAgent, microphones, microphone })
        consultationId.value = consultation._id
        consultationsStore.recordingConsultationId = consultationId.value
        console.log('Consultation started. ID:', consultationId.value)
        consultationStartTime.value = new Date() // Initialise the start time (frontend time) of the consultation start

        // Refresh the Bearer token to avoid disconnecting during the consultation capturing
        const { data } = await userDataService.refreshToken()
        const { data: user } = await userDataService.login(data)
        userStore.setUserData({ user, ...data })

        if (supportedMimeType.value) {
          mediaRecorder.value = new MediaRecorder(stream.value, {
            audioBitsPerSecond: 128000,
            mimeType: supportedMimeType.value
          })
        } else {
          console.error('No supported MIME types found for MediaRecorder.')
          logError(
            'Error: No supported MIME types found for MediaRecorder.',
            'useAudioRecording.ts:startRecording()',
            {}
          )
          mediaRecorder.value = new MediaRecorder(stream.value)
        }

        // console.log('SupportedMimeType: ', supportedMimeType.value)
        console.log(
          'MediaRecorder settings: ',
          mediaRecorder.value.mimeType,
          mediaRecorder.value.audioBitsPerSecond,
          'bps.'
        )

        mediaRecorder.value.ondataavailable = (event) => {
          // Send each chunk to the server as it becomes available
          if (event.data.size > 0 && state.fileName) {
            chunkCounter.value += 1
            console.log(`Sending chunk #${chunkCounter.value}:`, event.data)
            const formData = new FormData()
            formData.append('file', event.data, `chunk_${Date.now()}_${chunkCounter.value}`)
            formData.append('session_id', state.fileName)

            chunkBuffer.value[chunkCounter.value] = formData

            sendChunk(chunkCounter.value)
          }
        }

        mediaRecorder.value.onstart = async () => {
          if (isSupported.value) {
            try {
              await request('screen') // Request wake lock when recording starts
            } catch (error: any) {
              console.log(error)
            }
          }

          startTimer()

          recordStore.isRecording = true
          isPaused.value = false
        }

        mediaRecorder.value.onpause = async () => {
          pauseTimer()
          isPaused.value = true

          _updateStatus('paused')
        }

        mediaRecorder.value.onresume = async () => {
          if (stream.value) {
            const track = (stream.value.getAudioTracks() || [])[0]

            if (!stream.value?.active || (track && track?.muted)) {
              isMicAccessWasLost.value = true
              return
            }
          }

          resumeTimer()
          isPaused.value = false

          _updateStatus('started')
        }

        mediaRecorder.value.onstop = async () => {
          if (isSupported.value) {
            try {
              await release() // Release wake lock when recording stops
            } catch (error: any) {
              console.log(error)
            }
          }

          stopTimer()
          recordStore.isRecording = false

          // Stop audio recording tracking
          if (stream.value) {
            stream.value.getTracks().forEach((track) => {
              track.stop()
            })
          }
        }

        // mediaRecorder.value.onerror = _makeCounterRed
        // Create the fileName to send the chunks to the same file
        const userData = JSON.parse(localStorage.getItem('userData') || 'null')
        const username = userData.user.username.split('@')[0]
        state.fileName = consultationId.value + '-' + username

        // Warning popup if user want to reload the page during a consultation
        window.addEventListener('beforeunload', _beforeUnloadHandler)

        //mediaRecorder.value.start(1000); // Begin recording, make chunk of 1s
        mediaRecorder.value.start(60000) // 1 minute --> 60000
        initRecording.value = false

        _updateStatus('started')
      } catch (error: any) {
        console.error(error)
        logError(
          'Error: after authorization to use microphone',
          'useAudioRecording.ts:startRecording()',
          error
        )
        toaster.error(
          t('consultationIndexView.recordingProcess.toaster.micAccessDoesNotProvided'),
          { duration: Infinity, closeButton: true, dismissible: true }
        )
        recordStore.isRecording = false
        // toaster.error("Allow microphone access! Please reload page.", { closeButton: true, duration: Infinity });
        _updateStatus('error')
      }
    } else {
      alert('Audio recording is not supported in this browser.')
    }
  }

  async function cancelRecording() {
    // Notify user about status of capturing
    toaster.info(t('consultationIndexView.recordingProcess.toaster.capturingCanceled'))
    mediaRecorder.value?.stop()

    await _updateStatus('aborted')
    // Reset state
    resetState()
    await stopForegroundService()
  }

  async function endRecording() {
    await mediaRecorder.value?.stop()

    // Store current state if it's available
    if (isAvailableToStore.value) {
      toaster.success(t('consultationIndexView.recordingProcess.toaster.capturingComplete'))
      uploading.value = true

      await _updateStatus('stopped')

      await callback(null) // --> consolidate the audiofile and create consultation;

      uploading.value = false
      await stopForegroundService()
    }

    // Reset state
    resetState()
  }

  async function _updateStatus(
    status: 'aborted' | 'stopped' | 'started' | 'paused' | 'error',
    elapsed: number | null = null
  ) {
    const currentDate = new Date()
    const elapsedTime =
      elapsed || currentDate.getTime() - (consultationStartTime.value?.getTime() || 0)

    try {
      await consultationDataService.updateRecordingStatus(consultationId.value, status, elapsedTime)
    } catch (error: any) {
      if (consultationId.value && status === 'stopped') retryToUpdateStatus(status, elapsedTime)
    }
  }

  function onUpdateTimerCallback() {
    const track = (stream.value?.getAudioTracks() || [])[0]

    if ((stream.value && !stream.value.active) || (track && track.muted)) {
      if (recordStore.isRecording) {
        mediaRecorder.value?.pause()
      }

      isMicAccessWasLost.value = true
    }
  }

  async function sendChunk(chunkId: number) {
    consultationDataService
      .sendAudioChunkToServer(chunkBuffer.value[chunkId])
      .then(() => {
        console.log(
          'Send ',
          chunkId,
          Object.fromEntries((chunkBuffer.value[chunkId] || {}).entries())
        )

        if (chunkBuffer.value[chunkId]) {
          delete chunkBuffer.value[chunkId]
        }
      })
      .catch((error: any) => {
        if (chunkBuffer.value[chunkId]) {
          console.error('Error uploading chunk', error)
          retryChunk(chunkId)
        }
      })
  }

  async function retryChunk(id: number, retryDelay: number = 2000) {
    console.log(`Retry to send Chunk ID ${id}... (retry delay=${retryDelay})`)
    setTimeout(() => sendChunk(id), retryDelay)
  }

  async function retryToUpdateStatus(
    status: 'aborted' | 'stopped' | 'started' | 'paused',
    elapsedTime: number,
    retryDelay: number = 2000
  ) {
    console.log(`Retry to _updateStatus(${status})... (retry delay=${retryDelay})`)
    setTimeout(() => _updateStatus(status, elapsedTime), retryDelay)
  }

  function resetState() {
    state.file = ''
    state.description = ''
    state.fileName = ''
    state.audioFiles = []

    chunkBuffer.value = {}
    chunkCounter.value = 0

    recordStore.isRecording = false
    isPaused.value = false

    resetTimer()

    window.removeEventListener('beforeunload', _beforeUnloadHandler)
  }

  const _beforeUnloadHandler = (event: BeforeUnloadEvent) => {
    const message =
      'Consultation is ongoing, if you leave, you might lose data. Are you sure you want to leave?'
    event.preventDefault() // Some browsers require this line
    event.returnValue = message // Standard way to trigger the confirmation dialog
    return message // Some older browsers require this line
  }

  return {
    selectedFile,
    state,
    uploading,
    isMicAccessWasLost,
    isNotificationPermissionProvided,
    initRecording,
    isPaused,
    startRecording,
    cancelRecording,
    endRecording,
    mediaRecorder,
    isAvailableToStore,
    chunkCounter,
    chunkBuffer,
    stream,
    recordingDevice,
    duration,
    durationMms
  }
}
