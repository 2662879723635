import { useMarkdownContent } from './useMarkdownContent'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useLocalStorage } from '@vueuse/core'
import { logError } from '@/utils/error-logger'
import useDeviceAndBrowser from './useDeviceAndBrowser'
import { useConsultationsStore } from '@/stores/consultations'
import { SHOW_POPUP_FOR_VERSION } from '../constants/release-config'

export const useNewFeatureDialog = () => {
  const visible = ref(false)

  const startElapsedTime = ref<number>(0)

  const { locale } = useI18n()
  const { isMobile } = useDeviceAndBrowser()
  const consultationsStore = useConsultationsStore()

  const currentVersion = import.meta.env?.VITE_APP_VERSION || ''

  const storedVersion = useLocalStorage('app-version', '')
  const { markdown } = useMarkdownContent('./releases/')
  const image = computed(() => `./releases/${locale.value}.png`)

  const sendElapsedTime = (action: string) => {
    const _elapsedTime = startElapsedTime.value
      ? Math.round((new Date().getTime() - startElapsedTime.value) / 1000)
      : 0

    logError('Release popup clicked', action, {
      clickDelayInSeconds: _elapsedTime
    })

    startElapsedTime.value = 0
  }

  const showPopup = async () => {
    // Is new release
    if (isMobile || !currentVersion || currentVersion === storedVersion.value) return
    console.log(
      'onMounted 1',
      SHOW_POPUP_FOR_VERSION,
      currentVersion,
      SHOW_POPUP_FOR_VERSION !== currentVersion
    )
    console.log('onMounted 1', !consultationsStore.selectedConsultationId)
    // Is new release with new features?
    if (SHOW_POPUP_FOR_VERSION !== currentVersion || !consultationsStore.selectedConsultationId) {
      return
    }

    visible.value = true
    storedVersion.value = currentVersion

    startElapsedTime.value = new Date().getTime()
  }

  onMounted(async () => {
    showPopup()
  })

  watch(
    () => consultationsStore.selectedConsultationId,
    () => {
      showPopup()
    }
  )

  return {
    visible,
    markdown,
    image,
    sendElapsedTime
  }
}
