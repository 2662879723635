<template>
  <div
    class="relative mt-4 flex grow flex-col items-center justify-center gap-y-8 p-8 lg:mt-12 lg:gap-y-12"
  >
    <BaseSpinner
      v-if="consultationsStore.consolidatingConsultation"
      class="absolute left-0 top-0 z-[100] flex h-full w-full flex-col items-center justify-center bg-white"
    >
      <div class="mb-2 text-2xl">
        {{ $t('consultationIndexView.waitingScreens.consolidation.title') }}
      </div>
      <div class="mb-8 text-lg">
        {{ $t('consultationIndexView.waitingScreens.consolidation.description') }}
      </div>
    </BaseSpinner>

    <BaseDialog
      type="success"
      :title="consultationStartPopupMessageData?.title"
      :visible="consultationStartPopupVisible"
      @update:visible="hideConsultationStartPopup"
    >
      <ul class="text-left">
        <li v-for="(tip, idx) in consultationStartPopupMessageData?.text" :key="tip" class="mb-1">
          <span v-show="(consultationStartPopupMessageData?.text?.length || 0) > 1">
            {{ idx + 1 }}.
          </span>
          {{ tip }}
        </li>
      </ul>

      <template #actions>
        <button
          id="consultationStartPopupSubmitBtn"
          type="button"
          class="flex items-center justify-center gap-3 rounded-md border border-accent-500 bg-accent-500/10 px-4 py-2 text-xl font-semibold text-accent-500 hover:bg-accent-500/30 sm:ml-3 sm:w-auto xl:text-base"
          @click="hideConsultationStartPopup"
        >
          {{ consultationStartPopupMessageData?.button }}
        </button>
      </template>
    </BaseDialog>

    <BaseDialog
      v-model:visible="isMicAccessWasLost"
      type="success"
      disable-overlay
      :title="$t('dialogs.micWasLostDialog.title')"
      :okText="$t('dialogs.micWasLostDialog.actions.ok')"
      :cancelText="$t('dialogs.micWasLostDialog.actions.cancel')"
      :ok-disabled="uploading || !isAvailableToStore"
      @cancel="cancelRecording"
      @ok="endRecording"
    />

    <BaseDialog
      v-model:visible="forceEndConsultationDialogVisible"
      type="success"
      :title="$t('dialogs.forceEndConsultationDueToDurationLimitation.title')"
      :okText="$t('dialogs.forceEndConsultationDueToDurationLimitation.actions.ok')"
      :cancelText="null"
      @ok="forceEndConsultationDialogVisible = false"
    >
      <p>{{ $t('dialogs.forceEndConsultationDueToDurationLimitation.content.line1') }}</p>
      <p>{{ $t('dialogs.forceEndConsultationDueToDurationLimitation.content.line2') }}</p>
    </BaseDialog>

    <div class="text-center">
      <div class="min-h-16 pb-5 text-2xl text-black">
        <template v-if="consultationsStore.aiProcessingConsultation">
          {{ $t('consultationIndexView.recordingProcess.processingAudio.line1') }} <br />
          {{ $t('consultationIndexView.recordingProcess.processingAudio.line2') }}
        </template>

        <template v-else>{{ tipText }}</template>
      </div>
      <div class="mx-auto w-1/2 min-w-64 border-t border-gray-200" />
    </div>

    <BaseButton
      v-if="!recordStore.isRecording"
      :disabled="isStartBtnDisabled"
      id="startConsultationBtn"
      class="relative overflow-hidden"
      @click="handleStartConsultation"
    >
      <MicrophoneIcon class="h-6 w-6" />
      {{ $t('consultationIndexView.actions.startConsultation') }}

      <div
        v-show="uploading"
        class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-white/50"
      >
        <BaseSpinner />
      </div>
    </BaseButton>

    <!--div v-else class="flex flex-wrap items-center justify-center gap-5 lg:gap-8"-->
    <div
      v-if="recordStore.isRecording && !initRecording"
      class="flex flex-wrap items-center justify-center gap-5 lg:gap-8"
    >
      <BaseDialog
        type="warn"
        :title="$t('dialogs.cancelConsultation.title')"
        :okText="$t('dialogs.cancelConsultation.actions.ok')"
        :cancelText="$t('dialogs.cancelConsultation.actions.cancel')"
        @ok="cancelRecording"
      >
        <template #activator="{ toggleVisibility }">
          <BaseButton
            variant="outlined"
            id="cancelConsultationBtnInWarnDialog"
            @click.stop="toggleVisibility"
          >
            {{ $t('consultationIndexView.actions.cancelConsultation') }}
          </BaseButton>
        </template>

        <template #icon>
          <ExclamationTriangleIcon class="h-6 w-6" aria-hidden="true" />
        </template>
      </BaseDialog>

      <BaseButton
        variant="outlined"
        :disabled="uploading"
        id="pauseAndResumeConsultationBtn"
        @click="handleCapturing"
      >
        <PlayIcon v-if="isPaused" class="h-6 w-6" />
        <PauseIcon v-else class="h-6 w-6" />
        {{
          isPaused
            ? $t('consultationIndexView.actions.resume')
            : $t('consultationIndexView.actions.pause')
        }}
      </BaseButton>

      <BaseButton
        :disabled="uploading || !isAvailableToStore"
        id="endConsultationBtn"
        @click="endRecording"
      >
        <MicrophoneIcon class="h-6 w-6" />
        {{ $t('consultationIndexView.actions.endConsultation') }}
      </BaseButton>
    </div>

    <div class="text-center text-6xl text-[#747474]" :key="duration">
      {{ duration }}
    </div>

    <div
      v-if="recordStore.isRecording && !initRecording && !isPaused && isAndroid"
      class="text-center text-2xl font-bold text-black"
    >
      {{ $t('consultationIndexView.recordingProcess.androidTip') }}
    </div>

    <div
      v-if="recordStore.isRecording && !initRecording && !isPaused"
      class="flex flex-col items-center gap-4"
    >
      <AVMedia
        :media="stream"
        type="vbar"
        vbar-right-color="#E5F2EE"
        vbar-fill-color="#2f8a71"
        vbar-bg-color="#fff"
        :vbar-space="1"
        :vbar-width="6"
        :vbar-caps="true"
        :canv-height="20"
        :canv-width="50"
      />

      <p v-if="recordingDevice" class="text-center">
        {{ $t('consultationIndexView.info.inputDevice') }}: {{ recordingDevice }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue'
import { MicrophoneIcon } from '@heroicons/vue/24/outline'
import { PauseIcon, PlayIcon, ExclamationTriangleIcon } from '@heroicons/vue/24/solid'
//import AudioWaveIcon from '@/components/icons/AudioWaveIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseDialog from '@/components/base/BaseDialog.vue'
import useAudioRecording from '@/composables/useAudioRecording'
import { logError } from '@/utils/error-logger'
import BaseSpinner from '@/components/base/BaseSpinner.vue'
import consultationDataService from '@/services/consultationDataService'
import useDeviceAndBrowser from '@/composables/useDeviceAndBrowser'
import { useConsultationsStore } from '@/stores/consultations'
import { useI18n } from 'vue-i18n'

import { AVMedia } from 'vue-audio-visual'
import { useRecordStore } from '@/stores/record'
import { useAppStore } from '@/stores/app'
import { toaster } from '@/utils/toaster'

const MAX_CONSULTATION_DURATION_MMS = 125 * 1000 * 60 // 2h05min

const emit = defineEmits(['complete'])

const { t } = useI18n()
const {
  durationMms,
  duration,
  state,
  uploading,
  isMicAccessWasLost,
  isPaused,
  initRecording,
  mediaRecorder,
  startRecording,
  cancelRecording,
  endRecording,
  isAvailableToStore,
  chunkCounter,
  chunkBuffer,
  stream,
  recordingDevice
} = useAudioRecording(handleConsultation)
const {
  isAndroid,
  consultationStartPopupMessageData,
  consultationStartPopupVisible,
  checkDeviceAndBrowserAndShowTip,
  hideConsultationStartPopup
} = useDeviceAndBrowser()
const appStore = useAppStore()
const consultationsStore = useConsultationsStore()
const recordStore = useRecordStore()
const startingConsultation = ref(false)
const forceEndConsultationDialogVisible = ref(false)

const isStartBtnDisabled = computed(
  () => startingConsultation.value || uploading.value || recordStore.isRecording
)
const tipText = computed(() => {
  switch (true) {
    case initRecording.value:
      return t('consultationIndexView.recordingProcess.initCapture')
    case recordStore.isRecording:
      return t('consultationIndexView.recordingProcess.capturing')
    default:
      return t('consultationIndexView.recordingProcess.clickTOCapture')
  }
})

//const animated = computed(() => isRecording.value && !isPaused.value)

watch(isMicAccessWasLost, (newValue) => {
  if (!newValue) {
    isMicAccessWasLost.value = true
  }
})

watch(durationMms, (newValue) => {
  if (newValue >= MAX_CONSULTATION_DURATION_MMS) {
    endRecording()
    forceEndConsultationDialogVisible.value = true
  }
})

// When recording, disallow to reload page on mobile by scrolling down
watch(
  () => recordStore.isRecording,
  (newIsConsultationStarted) => {
    if (newIsConsultationStarted) {
      isPaused.value = false
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.overscrollBehavior = 'none'
    } else {
      document.documentElement.style.overflow = 'auto'
      document.documentElement.style.overscrollBehavior = 'auto'
    }
  },
  { immediate: true }
)

async function handleStartConsultation() {
  try {
    startingConsultation.value = true
    consultationsStore.aiProcessingConsultation = false

    if (!navigator.onLine) {
      toaster.warn('To start a consultation, you should be online')
      return
    }

    const current = new Date().getTime()
    const shouldInterruptForUpdatesPrompt = (await appStore.immediateCheckForUpdates()) || false
    const elapsedTime = new Date().getTime() - current
    console.log('[START_CONSULTATION] elapsed time: ', elapsedTime, 'ms')

    console.log(
      '[START_CONSULTATION] shouldInterruptForUpdatesPrompt',
      shouldInterruptForUpdatesPrompt
    )
    if (shouldInterruptForUpdatesPrompt) {
      return
    }

    const shouldInterruptForBrowserTips = checkDeviceAndBrowserAndShowTip
      ? checkDeviceAndBrowserAndShowTip()
      : false

    console.log('[START_CONSULTATION] shouldInterruptForBrowserTips', shouldInterruptForBrowserTips)
    if (shouldInterruptForBrowserTips) {
      return
    }

    startRecording()
  } catch (error: any) {
    throw error
  } finally {
    startingConsultation.value = false
  }
}

function handleCapturing() {
  if (isPaused.value) {
    mediaRecorder.value?.resume()
  } else {
    mediaRecorder.value?.pause()
  }
}

async function handleConsultation() {
  // Recording is over
  try {
    consultationsStore.consolidatingConsultation = true
    // Wait 1s to be sure the last chunk event triggered (otherwise buffer is empty when we pass the buffer length control, but the last chunk comes just after)
    await new Promise((resolve) => setTimeout(resolve, 1000))
    // Wait for chunks buffer to be empty before triggering the consolidation
    while (Object.keys(chunkBuffer.value).length !== 0) {
      console.log(
        `Wait for buffer to be empty... Current buffer length: ${Object.keys(chunkBuffer.value).length}`
      )
      await new Promise((resolve) => setTimeout(resolve, 500))
    }

    // Consolidate the audiofile
    console.log('-> Consolidating')
    const formData = new FormData()
    formData.append('session_id', state.fileName)
    const response = await consultationDataService.consolidateAudio(formData as any)
    if (response?.data) {
      emit('complete', response?.data?.consultation)
    } else {
      // something went wrong, notify the server
      logError(
        'Consolidation and consultation creation led to an error',
        'RecordConsultation.vue:handleConsultation()',
        response.data
      )
    }
    chunkCounter.value = 0
  } catch (error: any) {
    logError(
      'Error returns error on consolidateAudio',
      'RecordConsultation.vue:consolidateAudio()',
      error?.response?.data?.detail
    )
  } finally {
    consultationsStore.consolidatingConsultation = false
  }
}
</script>
